import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";

import { RootState } from "@store/store";
import { formatCurrency, formatYearsV2 } from "@helpers/formats";
import { EstatusAbortivos } from "@components/EstatusAbortivos";
import { createRowTable, createSimpleRow } from "@helpers/components";
import { useGetCompanyProfileQuery } from "@services/api/applications";
import { CategoriasAnalisisGrafico } from "@components/CategoriasAnalisisGrafico";
import { setDialogConfig, setPrintingMode, setPrintingProfileReady } from "@store/slices/componentsSlice";
import { DialogType } from "@interfaces/slices";

import DownloadIcon from "@assets/download_icon.svg";

export const Perfil = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState<Array<number>>([]);

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const printingMode = useSelector((state: RootState) => state.components.printingProfileReport.printingMode);

  const companyProfile = useGetCompanyProfileQuery(
    { rfc: applicationSelected.rfc, loanApplicationId: applicationSelected.id ?? "0-0-0-0-0" },
    {
      skip: applicationSelected.rfc === undefined,
    },
  );

  const handleOnDownloadReportClicked = () => {
    dispatch(setPrintingMode(true));
    dispatch(
      setDialogConfig({
        shown: true,
        fullscreen: true,
        type: DialogType.DESCARGAR_REPORTE,
        props: undefined,
      }),
    );
  };

  useEffect(() => {
    if (companyProfile.isSuccess && companyProfile.currentData) {
      if (companyProfile.data.analysis_categories === undefined || companyProfile.data.analysis_categories == null)
        return;

      const keys = Object.keys(companyProfile.data.analysis_categories).filter(
        (t) => ["csf_others", "total"].some((a) => a === t) === false,
      );

      const tmp = keys.map((key: string) => companyProfile.data.analysis_categories[key]);
      setValues(tmp);
    }

    if (printingMode) {
      dispatch(setPrintingProfileReady(companyProfile.isSuccess));
    }
  }, [companyProfile]);

  return (
    <Grid container pt={1}>
      <Grid size={12} textAlign={"end"} mb={2} display={printingMode ? "none" : "flex-end"}>
        <Button
          endIcon={<img src={DownloadIcon} />}
          variant="text"
          sx={{ color: "#528CD6" }}
          onClick={handleOnDownloadReportClicked}
        >
          Descargar Reporte
        </Button>
      </Grid>
      {printingMode && <Grid size={12} my={4}></Grid>}
      <Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
          Abortivos
        </Typography>
        <EstatusAbortivos status={companyProfile.currentData?.abortive_status} />
      </Grid>
      <Grid container direction={"column"} pt={4}>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
          Perfil Empresa
        </Typography>
        <Grid container>
          {createRowTable(
            "Ventas Anuales Facturadas",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details?.annual_sales_invoiced ?? "0"),
              "$",
              0,
            ),
            "Compras Anuales Facturadas",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details?.annual_expenses_invoiced ?? "0"),
              "$",
              0,
            ),
          )}
          {createRowTable(
            "Ingresos Anuales Facturados",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details?.annual_revenues_invoiced ?? "0"),
              "$",
              0,
            ),
            "Egresos Anuales Facturados",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details?.annual_expenses_invoiced ?? "0"),
              "$",
              0,
            ),
          )}
          {createRowTable(
            "Número de Empleados",
            companyProfile.currentData?.general_company_details?.number_employees ?? "0",
            "Nómina Mensual",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details?.monthly_payroll ?? "0"),
              "$",
              0,
            ),
          )}
          {createRowTable(
            "Score Pyme (Empresa)",
            companyProfile.currentData?.general_company_details?.score_company == "-1"
              ? "Sin información"
              : companyProfile.currentData?.general_company_details.score_company ?? "0",
            "BC Score (Persona)",
            companyProfile.currentData?.general_company_details?.bc_score == "-1"
              ? "Sin información"
              : companyProfile.currentData?.general_company_details.bc_score ?? "0",
          )}
          {createRowTable(
            "Score Syntage",
            companyProfile.currentData?.general_company_details?.score_syntage == "-1"
              ? "Sin información"
              : companyProfile.currentData?.general_company_details?.score_syntage ?? "",
            "Ingreso o Perdida Declarada",
            formatCurrency(companyProfile.currentData?.general_company_details?.declared_income_loss ?? 0, "$", 0),
          )}
          {createRowTable(
            "Estatus Listas Negras",
            companyProfile.currentData?.general_company_details?.status_backlists == "-1"
              ? "Sin información"
              : companyProfile.currentData?.general_company_details.status_backlists ?? "Ninguno",
            "Contrapartes Listas Negras",
            companyProfile.currentData?.general_company_details?.blacklists == "-1"
              ? "Sin información"
              : companyProfile.currentData?.general_company_details.blacklists ?? "Ninguno",
          )}
          {createRowTable(
            "Antigüedad",
            formatYearsV2(parseFloat(companyProfile.currentData?.general_company_details?.seniority ?? "0")),
            "Opinión de Cumplimiento",
            (companyProfile.currentData?.general_company_details?.compliance_opinion ?? "") === "negative"
              ? "Negativo"
              : "Positivo",
          )}
          {createSimpleRow(
            "Actividad Preponderante (%)",
            companyProfile.currentData?.general_company_details?.predominant_activity ?? "",
          )}
          {createSimpleRow("Uso del crédito", companyProfile.currentData?.credit_use ?? "")}
        </Grid>
      </Grid>
      <Grid container direction={"column"} width={"100%"}>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Categorías de Análisis
        </Typography>
        <Grid container direction={"row"}>
          <Grid container direction={"row"} size={"grow"} pr={4}>
            {createSimpleRow(
              "Ventas, Compras",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.sales_purchases) ?? 0, "")}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Flujo, Cancelación, Ratios",
              `${formatCurrency(
                Number(companyProfile.currentData?.analysis_categories?.flow_cancellation_ratios) ?? 0,
                "",
              )}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Clientes, Proveedores, P&G",
              `${formatCurrency(
                Number(companyProfile.currentData?.analysis_categories?.customers_suppliers_ps) ?? 0,
                "",
              )}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Nómina y empleados",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.payroll_employees) ?? 0, "")}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Financieros Declarados",
              `${formatCurrency(
                Number(companyProfile.currentData?.analysis_categories?.financial_declared) ?? 0,
                "",
              )}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Buró Acreditado",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.accredited_bureau) ?? 0, "")}%`,
              [4, 2, 6],
              <Button endIcon={<img src={DownloadIcon} />} loadingPosition="end"></Button>,
            )}
            {createSimpleRow(
              "Buró Persona",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.bureau_person) ?? 0, "")} %`,
              [4, 2, 6],
              <Button endIcon={<img src={DownloadIcon} />} loadingPosition="end"></Button>,
            )}
            {/* {createSimpleRow(
              "CSF & Otros",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.csf_others) ?? 0, "")}%`,
              [4, 8],
            )} */}
            <Grid size={{ xs: 4 }} p={1}>
              <Typography variant="body1" color={"#002652D9"} fontWeight={700}>
                Total:
              </Typography>
            </Grid>
            <Grid size={{ xs: 8 }} p={1}>
              <Typography color={"#002652D9"} fontWeight={600}>
                {`${formatCurrency(Number(companyProfile.currentData?.analysis_categories?.total) ?? 0, "")} %`}
              </Typography>
            </Grid>
            <Grid size={12} height={"50%"}></Grid>
          </Grid>
          <Grid size={6} mt={-15} pl={8}>
            <Box width={"100%"} maxHeight={"550px"} height={"100%"}>
              <CategoriasAnalisisGrafico data={values} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
