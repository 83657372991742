import { UUID } from "crypto";
import { DocumentInfo } from "./documents";

export enum DialogType {
  NONE,
  RECHAZAR_DOC,
  VALIDAR_DOC,
  VALIDAR_EXP,
  VALIDAR_DIC,
  BORRAR_DOC,

  AGENDAR_VISITA,
  RECHAZAR_APP,
  RECHAZAR_BLOQUEAR_APP,
  DOCS_FALTANTES,
  ESTATUS_NOTIFICACION,
  DESCARGAR_REPORTE,
}

export enum ButtonType {
  VALIDAR_AUTORIZACION,
  RECHAZAR,
  RECHAZAR_BLOQUEAR,
  VALIDAR_DICTAMEN,
  DOCS_FALTANTES,
  VALIDAR_EXPEDIENTE,
  VALIDAR_VISITA,
  DESCARGAR_EXPEDIENTE,
  VOLVER_VISITAR,
  LIBERAR_DISPERSION,
  CONVERTIR_CLIENTE,
}

export enum OptionApplicationMenu {
  PERFIL,
  DICTAMEN,
  EXPEDIENTE,
  VISITA_OCULAR,
  CONTRACTUALES,
  RESUMEN_CLIENTE,
}

export enum DocumentAction {
  SEE,
  DOWNLOAD,
  UPLOAD,
  DELETE,
  VALIDATE,
  VALIDATE_TO_SIGN,
  REJECT,
  REJECT_RELOAD,
  VALIDATE_SIGN,
  REJECT_SIGN,
  SEND_MIFIEL,
  CLIENT_SIGN,
  INTERNAL_SIGN,
}

export interface ApplicationSelected {
  id: UUID | undefined;
  solicitudId: number;
  razonSocial: string;
  rfc: string;
  nombre: string;
  montoSolicitado: string;
  plazoSolicitado: number;
  montoContratado: string;
  plazoContratado: number;
  tiempoPantalla: number;
  tiempoTotal: number;
  tiempoTotalProceso: string;
  etapa: string;
  subEtapa: string;
  estatus: string;
  pantalla: string;
  macroCanal: string;
  canal: string;
  asignacion: string;
  perdioInteres: number;
  dictamenCompletado: boolean;
  canalId: string;
  correoEjecutivo: string;
  fullData: string;
  company_docs_validated: boolean;
  shareholder_docs_validated: boolean;
  owners_docs_validated: boolean;
  tax_systems: {
    id: UUID | undefined;
    code: string;
    name: string;
  };
  status_code: string;
  existsSDC: boolean;
  montoSolicitadoOriginal: number;
  plazoSolicitadoOriginal: number;
  montoContratadoOriginal: number;
  plazoContratadoOriginal: number;
  usuario: {
    nombre: string;
    correo: string;
  };
  accionistaMayoritario: {
    nombre: string;
    rfc: string;
  };
  clientDetailId: UUID | undefined;
  productId: UUID | undefined;
  statusId: UUID | undefined;
  fechaModelo: string;
  loanApplicationConditionActive: {
    id: UUID | undefined;
  };
}

export interface RejectDialogProps {
  document: DocumentInfo;
}

export interface ValidateDialogProps {
  title: string;
}

export interface StatusNotificationDialogProps extends ValidateDialogProps {
  iconType: "success" | "error" | "warning" | "info" | "none";
}

export interface ValidateVisitDialogProps {
  title: string;
  date: string;
  time: string;
}

export interface DialogConfig {
  shown: boolean;
  fullscreen?: boolean;
  type: DialogType;
  props: undefined | RejectDialogProps | ValidateDialogProps | ValidateVisitDialogProps | StatusNotificationDialogProps;
  isProcessing?: boolean;
  onRejectClick?: () => void;
  onAcceptClick?: () => void;
  onCloseClick?: () => void;
}

export interface Shareholder {
  id: UUID;
  name: string;
  lastName: string;
  lastName2: string;
  totalValue: number;
  percent: number;
  legalAgent: boolean;
  majorityShareholder: boolean;
  actualOwner: boolean;
  toAdd: boolean;
  toDelete: boolean;
  toUpdate: boolean;
  isNew: boolean;
  personTypeCode: string;
}
