import { Container, Grid2 as Grid, Typography } from "@mui/material";

// import DownloadOkIcon from "@assets/download_ok_icon.svg";
import DownloadWaitIcon from "@assets/download_wait_icon.svg";
import DownloadWarningIcon from "@assets/download_warning_icon.svg";
import CheckIcon from "@mui/icons-material/Check";
import { AbortiveStatus } from "@interfaces/application";

interface EstatusAbortivosProps {
  status: AbortiveStatus | undefined;
}

export const EstatusAbortivos = (props: EstatusAbortivosProps) => {
  const createSectionStatus = (title: string, status: "Cumple" | "No Cumple") => {
    return (
      <Grid>
        <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {status === "Cumple" && <CheckIcon sx={{ color: "#528CD6", width: 13, height: 11 }} />}
          {status === "No Cumple" && <img src={DownloadWarningIcon} />}
          <Typography variant="body3" fontWeight={500} color="#002652" mt={0.2}>
            {title}
          </Typography>
          <Typography variant="body3" fontWeight={500} color={"#528CD6"}>
            {status}
          </Typography>
        </Container>
      </Grid>
    );
  };
  return (
    <Grid
      py={2}
      mr={3}
      rowSpacing={2}
      container
      sx={{ backgroundColor: "#E3F2F8", borderRadius: "8px" }}
      justifyContent={"space-between"}
    >
      {createSectionStatus("Ventas", props.status?.sales ?? false ? "No Cumple" : "Cumple")}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus("Antigüedad", props.status?.seniority ?? false ? "No Cumple" : "Cumple")}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus("Buró Empresa", props.status?.bureau_company ?? false ? "No Cumple" : "Cumple")}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus("Buró Persona", props.status?.bureau_person ?? false ? "No Cumple" : "Cumple")}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus("Cap. Pago", props.status?.payment_capacity ?? false ? "No Cumple" : "Cumple")}
    </Grid>
  );
};
