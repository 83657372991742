import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";

import dayjs from "dayjs";
import "dayjs/locale/es-mx";

import { RootState } from "@store/store";
import { DialogType } from "@interfaces/slices";
import { generateColorRamp } from "@helpers/colors";
import { GraficoDonaGenerico } from "@components/GraficoDonaGenerico";
import { GraficoBarrasGenerico } from "@components/GraficoBarrasGenerico";
import { GraficoBarrasHorizontalGenerico } from "@components/GraficoBarrasHorizontalGenerico";
import { setDialogConfig, setPrintingDashboardReady, setPrintingMode } from "@store/slices/componentsSlice";

import {
  usePostMonthlyCashFlowMutation,
  usePostMonthlyCustomerSupplierInvoiceMutation,
  usePostMonthlyExpendituresMutation,
  usePostMonthlyProductsAnsServicesMutation,
  usePostMonthlySalesMutation,
} from "@services/api/sat_core";

import DownloadIcon from "@assets/download_icon.svg";

export const Dashboard = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [periodSelected, setPeriodSelected] = useState<number>(0);
  const [monthlyCashFlow, setMonthlyCashFlow] = useState<{
    inflow: Array<number>;
    outflow: Array<number>;
    months: Array<string>;
  }>({ months: [], inflow: [], outflow: [] });

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const printingMode = useSelector((state: RootState) => state.components.printingProfileReport.printingMode);

  const [triggerPostSalesData, monthlySalesData] = usePostMonthlySalesMutation();
  const [triggerPostCashFlow, monthlyCashFlowData] = usePostMonthlyCashFlowMutation();
  const [triggerPostExpendituresData, monthlyExpendituresData] = usePostMonthlyExpendituresMutation();
  const [triggerPostMonthlyCustomerSupplierInvoice, monthlyCustomerSupplierInvoiceData] =
    usePostMonthlyCustomerSupplierInvoiceMutation();
  const [triggerPostMonthlyProductsAndServices, monthlyProductsAndServices] =
    usePostMonthlyProductsAnsServicesMutation();

  const handleOnPeriodClicked = (value: number) => {
    setPeriodSelected(value);
    const endDate = dayjs().format("YYYY-MM-01");
    const startDate = dayjs()
      .subtract(11 * (value + 1) + value, "month")
      .format("YYYY-MM-01");

    requestMonthlyData(applicationSelected.rfc, startDate, endDate);
  };

  const handleOnDownloadReportClicked = () => {
    dispatch(setPrintingMode(true));
    dispatch(
      setDialogConfig({
        shown: true,
        fullscreen: true,
        type: DialogType.DESCARGAR_REPORTE,
        props: undefined,
      }),
    );
  };

  const requestMonthlyData = async (rfc: string, startDate: string, endDate: string) => {
    try {
      setIsLoading(true);
      Promise.all([
        triggerPostSalesData({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
        triggerPostExpendituresData({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
        triggerPostCashFlow({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
        triggerPostMonthlyCustomerSupplierInvoice({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
        triggerPostMonthlyProductsAndServices({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
      ]).then(() => {
        setIsLoading(false);
      });
    } catch (error) {
      console.log("Error al consultar datos mensuales, %s", error);
    } finally {
      if (printingMode) {
        dispatch(setPrintingDashboardReady(true));
      }
    }
  };

  useEffect(() => {
    if (monthlyCashFlowData.isSuccess) {
      const monthNames = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

      const startDates = [
        ...new Set(
          monthlyCashFlowData.data.map((cashFlow) => {
            return cashFlow.startDate;
          }),
        ),
      ];

      const inflowTotals = startDates.map((startDate) => {
        return monthlyCashFlowData.data
          .filter((cashFlow) => cashFlow.type === "inflow")
          .filter((inflow) => inflow.startDate === startDate)
          .reduce((acc, currentValue) => acc + currentValue.mxnAmount, 0);
      });

      const outflowTotals = startDates.map((startDate) => {
        return monthlyCashFlowData.data
          .filter((cashFlow) => cashFlow.type === "outflow")
          .filter((outflow) => outflow.startDate === startDate)
          .reduce((acc, currentValue) => acc + currentValue.mxnAmount * -1, 0);
      });

      const months = startDates.map((startDate) => {
        const tmp = startDate.split("-");
        return `${monthNames[Number(tmp[1]) - 1]} ${tmp[0]}`;
      });

      setMonthlyCashFlow({
        months: months,
        inflow: inflowTotals,
        outflow: outflowTotals,
      });
    }
  }, [monthlyCashFlowData]);

  useEffect(() => {
    const endDate = dayjs().format("YYYY-MM-01");
    const startDate = dayjs().subtract(11, "month").format("YYYY-MM-01");

    requestMonthlyData(applicationSelected.rfc, startDate, endDate);
  }, []);

  return (
    <Grid container pt={1}>
      <Grid
        container
        width={"100%"}
        direction={"row"}
        justifyContent={"space-around"}
        display={printingMode ? "none" : ""}
      >
        <Grid size={"grow"} display={"flex"} columnGap={2} alignItems={"center"}>
          <Typography variant="body1" color="#002652" fontWeight={700}>
            Periodo
          </Typography>
          <Button
            onClick={() => handleOnPeriodClicked(0)}
            sx={{
              backgroundColor: periodSelected === 0 ? "#528CD6" : "#A3D4E8",
              color: periodSelected === 0 ? "#FFF" : "#000",
              fontSize: "13px",
            }}
          >
            1 Año
          </Button>
          <Button
            onClick={() => handleOnPeriodClicked(1)}
            sx={{
              backgroundColor: periodSelected === 1 ? "#528CD6" : "#A3D4E8",
              color: periodSelected === 1 ? "#FFF" : "#000",
              fontSize: "13px",
            }}
          >
            2 Años
          </Button>
          <Button
            onClick={() => handleOnPeriodClicked(2)}
            sx={{
              backgroundColor: periodSelected === 2 ? "#528CD6" : "#A3D4E8",
              color: periodSelected === 2 ? "#FFF" : "#000",
              fontSize: "13px",
            }}
          >
            3 Años
          </Button>
        </Grid>
        <Grid>
          <Button
            endIcon={<img src={DownloadIcon} />}
            variant="text"
            sx={{ color: "#528CD6" }}
            onClick={handleOnDownloadReportClicked}
          >
            Descargar Reporte
          </Button>
        </Grid>
      </Grid>
      <Grid p={2} width={"100%"}>
        <GraficoBarrasGenerico
          isLoading={isLoading}
          title="Ventas"
          labels={monthlySalesData.isSuccess ? monthlySalesData.data.map((sale) => sale.dateLabel) : []}
          colors={["rgb(0, 38, 84)"]}
          data={[monthlySalesData.isSuccess ? monthlySalesData.data.map((sale) => sale.amount) : []]}
        />
      </Grid>
      <Grid p={2} mt={2} width={"100%"}>
        <GraficoBarrasGenerico
          isLoading={isLoading}
          title="Gastos"
          labels={
            monthlyExpendituresData.isSuccess
              ? monthlyExpendituresData.data.map((expenditure) => expenditure.dateLabel)
              : []
          }
          colors={["rgb(58, 142, 220)"]}
          data={[
            monthlyExpendituresData.isSuccess
              ? monthlyExpendituresData.data.map((expenditure) => expenditure.amount)
              : [],
          ]}
        />
      </Grid>
      <Grid p={2} mt={2} width={"100%"}>
        <GraficoBarrasGenerico
          isLoading={isLoading}
          stacked
          title="Flujo"
          subtitle="(Ingreso, Egresos)"
          labels={monthlyCashFlow.months}
          colors={["rgb(0, 38, 84)", "rgb(242, 112, 79)"]}
          data={[monthlyCashFlow.inflow, monthlyCashFlow.outflow]}
        />
      </Grid>
      <Grid p={2} mt={2} width={"100%"} container>
        <Grid size={12} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}>
          <Typography variant="h3" fontWeight={700} color="#002652" mb={2}>
            Clientes y Proveedores
          </Typography>
        </Grid>
        <Grid size={6} mt={1}>
          <Box borderRight={"1px solid rgba(163, 212, 232, 0.5)"} px={2}>
            <Typography variant="h4" fontWeight={600} color={"#002652"}>
              Top Clientes
            </Typography>
            <GraficoBarrasHorizontalGenerico
              isLoading={isLoading}
              labels={
                monthlyCustomerSupplierInvoiceData.data?.clients.map((client) => {
                  if (client.name.length >= 25) {
                    const middle = Math.floor(client.name.length / 2);
                    return [client.name.slice(0, middle).toUpperCase(), client.name.slice(middle).toUpperCase()];
                  }
                  return [client.name];
                }) ?? []
              }
              data={monthlyCustomerSupplierInvoiceData.data?.clients.map((client) => client.total) ?? []}
              color={"rgb(0, 38, 84)"}
              showDataLabels
              labelFormatter={(value, _) => {
                return `$${(Number(value) / 1000000).toFixed(3)} M`;
              }}
            />
          </Box>
        </Grid>
        <Grid size={6} mt={1}>
          <Box px={2}>
            <Typography variant="h4" fontWeight={600} color={"#002652"}>
              Top Proveedores
            </Typography>
            {
              <GraficoBarrasHorizontalGenerico
                isLoading={isLoading}
                color={"rgb(0, 38, 84)"}
                labels={
                  monthlyCustomerSupplierInvoiceData.data?.suppliers.map((supplier) => {
                    if (supplier.name.length >= 25) {
                      const middle = Math.floor(supplier.name.length / 2);
                      return [supplier.name.slice(0, middle).toUpperCase(), supplier.name.slice(middle).toUpperCase()];
                    }
                    return [supplier.name];
                  }) ?? []
                }
                data={monthlyCustomerSupplierInvoiceData.data?.suppliers.map((supplier) => supplier.total) ?? []}
                showDataLabels
                labelFormatter={(value, _) => {
                  return `$${(Number(value) / 1000000).toFixed(3)} M`;
                }}
              />
            }
          </Box>
        </Grid>
      </Grid>
      <Grid p={2} mt={2} width={"100%"} container>
        <Grid size={12} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}>
          <Typography variant="h3" fontWeight={700} color="#002652" mb={2}>
            Productos y Servicios
          </Typography>
        </Grid>
        <Grid size={6} mt={1}>
          <Box borderRight={"1px solid rgba(163, 212, 232, 0.5)"}>
            <GraficoDonaGenerico
              isLoading={isLoading}
              title="Vendidos"
              data={
                monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.sold.map((t) => t.total) : []
              }
              labels={
                monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.sold.map((t) => t.name) : []
              }
              colors={generateColorRamp(
                "#101e3a",
                "#b0c3ea",
                monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.sold.length : 0,
              )}
              labelFormatter={(value, ctx) => {
                return `$${(Number(value) / 1000000).toFixed(2)} M\n${
                  monthlyProductsAndServices.data?.sold[ctx.dataIndex].share
                }%`;
              }}
            />
          </Box>
        </Grid>
        <Grid size={6} mt={1}>
          <Box>
            <GraficoDonaGenerico
              isLoading={isLoading}
              title="Comprados"
              data={
                monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.bought.map((t) => t.total) : []
              }
              labels={
                monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.bought.map((t) => t.name) : []
              }
              colors={[
                ...generateColorRamp(
                  "#101e3a",
                  "#b0c3ea",
                  monthlyProductsAndServices.isSuccess ? monthlyProductsAndServices.data.sold.length - 2 : 0,
                ),
                "#ffc9c9",
              ]}
              labelFormatter={(value, ctx) => {
                return `$${(Number(value) / 1000000).toFixed(2)} M\n${
                  monthlyProductsAndServices.data?.bought[ctx.dataIndex].share
                }%`;
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
