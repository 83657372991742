import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  FormControl,
  Grid2 as Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { NumberFormatValues, NumericFormat } from "react-number-format";

import dayjs from "dayjs";

import { RootState } from "@store/store";
import { formatCurrency } from "@helpers/formats";
import { createRowTable } from "@helpers/components";
import { EstatusDescargasSat } from "@components/EstatusDescargasSat";
import { setApplicationStateCode } from "@store/slices/appSlice";
import { setDialogConfig, setPrintingGeneralReady, setPrintingMode } from "@store/slices/componentsSlice";
import { DialogType } from "@interfaces/slices";

import {
  useGetCompanyProfileQuery,
  usePostStatusApplicationMutation,
  usePutConditionMutation,
} from "@services/api/applications";

import DownloadIcon from "@assets/download_icon.svg";
import ValidateEnableIcon from "@assets/validate_enable_icon.svg";
import RejectDisabledIcon from "@assets/reject_disabled_icon.svg";

export const General = () => {
  const dispatch = useDispatch();

  const userSession = useSelector((state: RootState) => state.app.session.user);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const printingMode = useSelector((state: RootState) => state.components.printingProfileReport.printingMode);

  const [resultSelected, setResultSelected] = useState<string>("");
  const [authorizedTerm, setAuthorizedTerm] = useState<number>(applicationSelected.plazoContratadoOriginal);
  const [authorizedAmount, setAuthorizedAmount] = useState<number>(applicationSelected.montoContratadoOriginal);

  const [triggerPutCondition] = usePutConditionMutation();
  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const companyProfile = useGetCompanyProfileQuery(
    { rfc: applicationSelected.rfc, loanApplicationId: applicationSelected.id ?? "0-0-0-0-0" },
    {
      skip: applicationSelected.rfc === undefined,
    },
  );

  const getApplicationResult = () => {
    let result = "";
    if (
      [
        "CSEVE",
        "CSVEX",
        "CSAVC",
        "CSVYC",
        "CSVVC",
        "CSEVD",
        "CSFIR",
        "CSVCF",
        "CSDIS",
        "CSAAO",
        "CSBEX",
        "CSDIC",
        "CSDOC",
        "CSCDO",
        "CDOEM",
        "CSAED",
        "CSECI",
        "CSEDC",
        "CSEDV",
      ].includes(applicationSelected.status_code)
    ) {
      result = "Autorizado";
    }

    if (["CSREC", "CSRDO"].includes(applicationSelected.status_code)) {
      result = "Rechazado";
    }

    return result;
  };

  const handleOnResultUpdateAccepted = async (lastResult: string, newResult: string) => {
    try {
      await triggerPostStatusApplication({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        userName: userSession.name.concat(" ", userSession.last_name, " ", userSession.last_name_2).trim(),
        statusCode: newResult === "Autorizado" ? "CSAAO" : "CSREC",
        disableSequenceCheck: true,
      }).unwrap();

      dispatch(setApplicationStateCode(newResult === "Autorizado" ? "CSAAO" : "CSREC"));
    } catch (error) {
      setResultSelected(lastResult);
      console.log("No se pudo cambiar de estatus la solicitud: %s", error);
    } finally {
      dispatch(
        setDialogConfig({
          isProcessing: false,
          shown: false,
          type: DialogType.VALIDAR_DOC,
          props: undefined,
        }),
      );
    }
  };

  const handleOnResultUpdateCanceled = (lastResult: string) => {
    setResultSelected(lastResult);
  };

  const handleOnResultChanged = (event: SelectChangeEvent) => {
    const last = resultSelected;
    setResultSelected(event.target.value);
    dispatch(
      setDialogConfig({
        shown: true,
        type: DialogType.VALIDAR_DOC,
        props: {
          title: "Esta seguro de cambiar el resultado de la solicitud de:",
        },
        onAcceptClick: () => {
          handleOnResultUpdateAccepted(last, event.target.value);
        },
        onRejectClick: () => {
          handleOnResultUpdateCanceled(last);
        },
      }),
    );
  };

  const handleOnAuthorizedAmountUpdateAccepted = async (authorizedAmount: number) => {
    try {
      await triggerPutCondition({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        conditionId: applicationSelected.loanApplicationConditionActive.id ?? "0-0-0-0-0",
        body: {
          approved_amount: authorizedAmount,
        },
      }).unwrap();
    } catch (error) {
      console.log("No se pudo cambiar el monto máximo autorizado de la solicitud: %s", error);
    } finally {
      dispatch(
        setDialogConfig({
          isProcessing: false,
          shown: false,
          type: DialogType.VALIDAR_DOC,
          props: undefined,
        }),
      );
    }
  };

  const handleOnAuthorizedAmountSave = () => {
    dispatch(
      setDialogConfig({
        shown: true,
        type: DialogType.VALIDAR_DOC,
        props: {
          title: "Esta seguro de cambiar el monto máximo autorizado de:",
        },
        onAcceptClick: () => {
          handleOnAuthorizedAmountUpdateAccepted(authorizedAmount);
        },
        onRejectClick: () => {
          setAuthorizedAmount(applicationSelected.montoContratadoOriginal);
        },
      }),
    );
  };

  const handleAuthorizedTermUpdateAccepted = async (authorizedTerm: number) => {
    try {
      await triggerPutCondition({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        conditionId: applicationSelected.loanApplicationConditionActive.id ?? "0-0-0-0-0",
        body: {
          approved_term: authorizedTerm,
        },
      }).unwrap();
    } catch (error) {
      console.log("No se pudo cambiar el plazo máximo autorizado de la solicitud: %s", error);
    } finally {
      dispatch(
        setDialogConfig({
          isProcessing: false,
          shown: false,
          type: DialogType.VALIDAR_DOC,
          props: undefined,
        }),
      );
    }
  };

  const handleOnAuthorizedTermSave = () => {
    dispatch(
      setDialogConfig({
        shown: true,
        type: DialogType.VALIDAR_DOC,
        props: {
          title: "Esta seguro de cambiar el plazo máximo autorizado de:",
        },
        onAcceptClick: () => {
          handleAuthorizedTermUpdateAccepted(authorizedTerm);
        },
        onRejectClick: () => {
          setAuthorizedTerm(applicationSelected.plazoContratadoOriginal);
        },
      }),
    );
  };

  const handleOnDownloadReportClicked = () => {
    dispatch(setPrintingMode(true));
    dispatch(
      setDialogConfig({
        shown: true,
        fullscreen: true,
        type: DialogType.DESCARGAR_REPORTE,
        props: undefined,
      }),
    );
  };

  useEffect(() => {
    setResultSelected(getApplicationResult());
    if (printingMode) {
      dispatch(setPrintingGeneralReady(companyProfile.isSuccess));
    }
  }, [companyProfile]);

  return (
    <Grid container pt={1}>
      <Grid size={12} textAlign={"end"} mb={2} display={printingMode ? "none" : "flex-end"}>
        <Button
          endIcon={<img src={DownloadIcon} />}
          variant="text"
          sx={{ color: "#528CD6" }}
          onClick={handleOnDownloadReportClicked}
        >
          Descargar Reporte
        </Button>
      </Grid>
      <Grid container direction={"column"}>
        <Grid container>
          <Grid size={8}>
            <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
              Descargas
            </Typography>
            <EstatusDescargasSat />
          </Grid>
          <Grid size={4}>
            <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
              Etapa
            </Typography>
            <Box pr={5}>
              <Grid container textAlign={"center"} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#0026528C">
                    Etapa
                  </Typography>
                </Grid>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#0026528C">
                    Subetapa
                  </Typography>
                </Grid>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#0026528C">
                    Estatus/Pantalla
                  </Typography>
                </Grid>
              </Grid>
              <Grid container textAlign={"center"}>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#528CD6" pt={1}>
                    {applicationSelected.etapa}
                  </Typography>
                </Grid>
                <Grid
                  size={4}
                  borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
                  borderRight={"1px solid rgba(163, 212, 232, 0.5)"}
                >
                  <Typography variant="body2" pt={1}>
                    {applicationSelected.subEtapa}
                  </Typography>
                </Grid>
                <Grid size={4}>
                  <Typography variant="body2" pt={1}>
                    {applicationSelected.estatus}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Modelo
        </Typography>
        <Grid container>
          {createRowTable(
            "ID Solicitud",
            applicationSelected.solicitudId.toString(),
            "ID Modelo",
            companyProfile.currentData?.result_model.id_model ?? "",
          )}
          <Grid size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
            <div>
              <Grid container>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Resultado
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  {printingMode ? (
                    <Typography color={resultSelected == "Autorizado" ? "#01A388" : "#E35050"}>
                      {resultSelected}
                    </Typography>
                  ) : (
                    <FormControl fullWidth size="small">
                      <Select value={resultSelected} onChange={handleOnResultChanged}>
                        <MenuItem value={"Autorizado"}>
                          <Typography color="#01A388" fontWeight={600}>
                            Autorizado
                          </Typography>
                        </MenuItem>
                        <MenuItem value={"Rechazado"}>
                          <Typography color="#E35050" fontWeight={600}>
                            Rechazado
                          </Typography>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            size={{ xs: 6 }}
            p={1}
            borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
            borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
          >
            <div>
              <Grid container ml={4}>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    RFC
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Typography color={"#002652"}>{applicationSelected.rfc}</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {createRowTable(
            "Calificación:",
            companyProfile.currentData?.result_model.score_code ?? "",
            "Score",
            formatCurrency(Number(companyProfile.currentData?.result_model.score) ?? 0, "", 1),
          )}
          {createRowTable(
            "Monto Seleccionado:",
            formatCurrency(applicationSelected.montoSolicitadoOriginal ?? 0, "$", 0),
            "Fecha Inicio Solicitud",
            companyProfile.currentData?.created_at
              ? dayjs(companyProfile.currentData.date_loan_application)
                  .utc(true)
                  .local()
                  .locale("es-mx")
                  .format("DD/MMM/YYYY - HH:mm:ss")
              : "--/--/---- - --:--:--",
          )}
          <Grid size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
            <div>
              <Grid container>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Monto Máximo Autorizado
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }} container display={"flex"} flexDirection={"row"} columnGap={1}>
                  {printingMode ? (
                    <Grid size={8}>
                      <Typography fontWeight={600} color="#528CD6">
                        {formatCurrency(authorizedAmount, "$", 0)}
                      </Typography>
                    </Grid>
                  ) : (
                    <>
                      <Grid size={8}>
                        <NumericFormat
                          fullWidth
                          size="small"
                          prefix="$"
                          displayType="input"
                          sx={{ fontWeight: 600, "& .MuiInputBase-root": { color: "#528CD6", fontWeight: 600 } }}
                          thousandSeparator
                          customInput={TextField}
                          autoComplete="off"
                          value={authorizedAmount}
                          onValueChange={(event: NumberFormatValues) => setAuthorizedAmount(event.floatValue ?? 0)}
                        />
                      </Grid>
                      <Grid size={1}>
                        <IconButton onClick={handleOnAuthorizedAmountSave}>
                          <img src={ValidateEnableIcon} />
                        </IconButton>
                      </Grid>
                      <Grid size={1}>
                        <IconButton onClick={() => setAuthorizedAmount(applicationSelected.montoContratadoOriginal)}>
                          <img src={RejectDisabledIcon} />
                        </IconButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            size={{ xs: 6 }}
            p={1}
            borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
            borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
          >
            <div>
              <Grid container ml={4}>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Fecha Modelo
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Typography color={"#002652"}>
                    {companyProfile.currentData?.result_model.date_model
                      ? dayjs(companyProfile.currentData?.result_model.date_model)
                          .utc(true)
                          .local()
                          .locale("es-mx")
                          .format("DD/MMM/YYYY - HH:mm:ss")
                      : "--/--/---- - --:--:--"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {createRowTable(
            "Plazo seleccionado",
            `${applicationSelected.plazoSolicitadoOriginal ?? 0} meses`,
            "Días de Validez Modelo",
            companyProfile?.currentData?.result_model.days_validity_model.toString() ?? "0",
          )}
          <Grid size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
            <div>
              <Grid container>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Plazo Máximo Autorizado
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }} container display={"flex"} flexDirection={"row"} columnGap={1}>
                  {printingMode ? (
                    <Grid size={8}>
                      <Typography fontWeight={600} color="#528CD6">
                        {authorizedTerm} meses
                      </Typography>
                    </Grid>
                  ) : (
                    <>
                      <Grid size={8}>
                        <NumericFormat
                          fullWidth
                          size="small"
                          suffix=" meses"
                          displayType="input"
                          sx={{ fontWeight: 600, "& .MuiInputBase-root": { color: "#528CD6", fontWeight: 600 } }}
                          thousandSeparator
                          customInput={TextField}
                          autoComplete="off"
                          value={authorizedTerm}
                          onValueChange={(event: NumberFormatValues) => setAuthorizedTerm(event.floatValue ?? 0)}
                        />
                      </Grid>
                      <Grid size={1}>
                        <IconButton onClick={handleOnAuthorizedTermSave}>
                          <img src={ValidateEnableIcon} />
                        </IconButton>
                      </Grid>
                      <Grid size={1}>
                        <IconButton
                          onClick={() => {
                            setAuthorizedTerm(applicationSelected.plazoContratadoOriginal);
                          }}
                        >
                          <img src={RejectDisabledIcon} />
                        </IconButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            size={{ xs: 6 }}
            p={1}
            borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
            borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
          >
            <div>
              <Grid container ml={4}>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Tasa
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Typography color={"#002652"}>
                    {`${formatCurrency(companyProfile.currentData?.result_model.interest_rate ?? 0, "", 1)}%`}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Usuario y Accionista
        </Typography>
        <Grid container>
          {createRowTable(
            "Usuario Solicitud",
            applicationSelected.usuario.nombre,
            "Correo Usuario",
            applicationSelected.usuario.correo,
          )}
          {createRowTable(
            "Accionista Mayoritario",
            applicationSelected.accionistaMayoritario.nombre ?? "-",
            "RFC Accionista Mayoritario",
            applicationSelected.accionistaMayoritario.rfc ?? "-",
          )}
        </Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Generales
        </Typography>
        <Grid container>
          {createRowTable(
            "Nombre Comercial",
            companyProfile.currentData?.general_data?.company_name ?? "",
            "Pagina Web",
            companyProfile.currentData?.general_data?.web_page ?? "",
          )}
          {createRowTable(
            "Linkedin",
            companyProfile.currentData?.general_data?.linkedin ?? "",
            "Facebook",
            companyProfile.currentData?.general_data?.facebook ?? "",
          )}
          {createRowTable(
            "Twitter / X",
            companyProfile.currentData?.general_data?.x_twitter ?? "",
            "Instagram",
            companyProfile.currentData?.general_data?.instagram ?? "",
          )}
        </Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Origen
        </Typography>
        <Grid container>
          {createRowTable(
            "ID Canal",
            applicationSelected.canalId == "" ? "00000" : applicationSelected.canalId,
            "Canal",
            applicationSelected.canal,
          )}
          {createRowTable("Macrocanal", applicationSelected.macroCanal, "Detalle Canal", "")}
        </Grid>
      </Grid>
    </Grid>
  );
};
