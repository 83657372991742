import { lazy, Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { MainLayout } from "./layouts/MainLayout";
import { SolicitudLayout } from "./layouts/SolicitudLayout";

const Login = lazy(() => import("@pages/Login"));
const Dashboard = lazy(() => import("@pages/Dashboard"));
const Solicitud = lazy(() => import("@pages/Solicitud/Solicitud"));
const RecuperarCuenta = lazy(() => import("@pages/RecuperarCuenta"));
const CambiarContrasenia = lazy(() => import("@pages/CambiarContrasenia"));

import "./App.css";

const SuspenseLayout = () => {
  return (
    <Suspense fallback={<></>}>
      <Outlet />
    </Suspense>
  );
};

function App() {
  return (
    <Routes>
      <Route element={<SuspenseLayout />}>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<MainLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="solicitud" element={<SolicitudLayout />}>
            <Route index element={<Solicitud />} />
          </Route>

          <Route path="cambiar_contrasenia" element={<CambiarContrasenia />} />
          <Route path="recuperar_cuenta" element={<RecuperarCuenta />} />
        </Route>
        <Route path="*" element={<Navigate to={"/login"} />} />
      </Route>
    </Routes>
  );
}

export default App;
