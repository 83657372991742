import { Dialog } from "@mui/material";

import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "@store/store";
import {
  DialogType,
  StatusNotificationDialogProps,
  RejectDialogProps,
  ValidateDialogProps,
  ValidateVisitDialogProps,
} from "@interfaces/slices";

import { Validar } from "./DialogosSegmentos/Validar";
import { RechazarDocumento } from "./DialogosSegmentos/RechazarDocumento";
import { RechazarSolicitud } from "./DialogosSegmentos/RechazarSolicitud";
import { DocumentosFaltantes } from "./DialogosSegmentos/DocumentosFaltantes";
import { EstatusNotificacion } from "./DialogosSegmentos/EstatusNotificacion";
import { DescargarReporte } from "./DialogosSegmentos/DescargarReporte";

export const DialogManager = () => {
  const dialogConfig = useSelector((state: RootState) => state.components.dialog);

  const dialogs = new Map<DialogType, React.ReactNode>([
    [DialogType.NONE, <></>],
    [
      DialogType.RECHAZAR_DOC,
      <RechazarDocumento
        document={(dialogConfig.props as RejectDialogProps)?.document}
        isProcessing={dialogConfig?.isProcessing ?? false}
        onBackClick={dialogConfig.onRejectClick ?? (() => {})}
        onConfirmClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
    [
      DialogType.VALIDAR_DOC,
      <Validar
        type={"VALIDATE_DOC"}
        title={(dialogConfig.props as ValidateDialogProps)?.title}
        isProcessing={dialogConfig?.isProcessing}
        onBackClick={dialogConfig.onRejectClick ?? (() => {})}
        onContinueClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
    [
      DialogType.VALIDAR_EXP,
      <Validar
        type={"VALIDATE_EXP"}
        title={(dialogConfig.props as ValidateDialogProps)?.title}
        isProcessing={dialogConfig?.isProcessing}
        onBackClick={dialogConfig.onRejectClick ?? (() => {})}
        onContinueClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
    [
      DialogType.VALIDAR_DIC,
      <Validar
        type={"COMPLETE"}
        title={(dialogConfig.props as ValidateDialogProps)?.title}
        isProcessing={dialogConfig?.isProcessing}
        onBackClick={dialogConfig.onRejectClick ?? (() => {})}
        onContinueClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
    [
      DialogType.AGENDAR_VISITA,
      <Validar
        type={"VISIT"}
        title={(dialogConfig.props as ValidateDialogProps)?.title}
        date={(dialogConfig.props as ValidateVisitDialogProps)?.date}
        time={(dialogConfig.props as ValidateVisitDialogProps)?.time}
        isProcessing={dialogConfig?.isProcessing}
        onBackClick={dialogConfig.onRejectClick ?? (() => {})}
        onContinueClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
    [
      DialogType.BORRAR_DOC,
      <Validar
        type={"DELETE_DOC"}
        title={(dialogConfig.props as ValidateDialogProps)?.title}
        isProcessing={dialogConfig?.isProcessing}
        onBackClick={dialogConfig.onRejectClick ?? (() => {})}
        onContinueClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
    [
      DialogType.RECHAZAR_APP,
      <RechazarSolicitud
        onBackClick={dialogConfig.onRejectClick ?? (() => {})}
        onConfirmClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
    [
      DialogType.DOCS_FALTANTES,
      <DocumentosFaltantes
        onBackClick={dialogConfig.onRejectClick ?? (() => {})}
        onConfirmClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
    [
      DialogType.ESTATUS_NOTIFICACION,
      <EstatusNotificacion
        title={(dialogConfig.props as StatusNotificationDialogProps)?.title}
        iconType={(dialogConfig.props as StatusNotificationDialogProps)?.iconType}
        onContinueClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
    [
      DialogType.DESCARGAR_REPORTE,
      <DescargarReporte
      // title={(dialogConfig.props as StatusNotificationDialogProps)?.title}
      // iconType={(dialogConfig.props as StatusNotificationDialogProps)?.iconType}
      // onContinueClick={dialogConfig.onAcceptClick ?? (() => {})}
      />,
    ],
  ]);

  return (
    <Dialog open={dialogConfig.shown} fullScreen={dialogConfig.fullscreen}>
      {dialogs.get(dialogConfig.type)}
    </Dialog>
  );
};
