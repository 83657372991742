import { Button, Grid2 as Grid } from "@mui/material";

import { ButtonType, DialogType } from "@interfaces/slices";
import { setButtonClicked, setDialogConfig } from "@store/slices/componentsSlice";
import { RootState } from "@store/store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface ButtonConfig {
  text: string;
  color: string;
  disabledColor: string;
  fontColor: string;
}

export const ButtonPanel = () => {
  const dispatch = useDispatch();

  const buttonsVisibles = useSelector((state: RootState) => state.components.buttonPanel.buttons);

  const handleOnButtonClicked = (event: React.SyntheticEvent) => {
    switch (Number(event.currentTarget.getAttribute("data-type")) as ButtonType) {
      case ButtonType.DOCS_FALTANTES:
        dispatch(
          setDialogConfig({
            shown: true,
            type: DialogType.DOCS_FALTANTES,
            props: undefined,
          }),
        );
        break;
      case ButtonType.RECHAZAR:
        dispatch(
          setDialogConfig({
            shown: true,
            type: DialogType.RECHAZAR_APP,
            props: undefined,
          }),
        );
        break;
      case ButtonType.RECHAZAR_BLOQUEAR:
        dispatch(
          setDialogConfig({
            shown: false,
            type: DialogType.RECHAZAR_BLOQUEAR_APP,
            props: undefined,
          }),
        );
        break;
      case ButtonType.VALIDAR_EXPEDIENTE:
        dispatch(
          setDialogConfig({
            shown: true,
            type: DialogType.VALIDAR_EXP,
            props: {
              title: "Estas seguro de validar el expediente de:",
            },
            onAcceptClick: () => {
              dispatch(setButtonClicked({ btn: ButtonType.VALIDAR_EXPEDIENTE, clicked: true }));
            },
          }),
        );
        break;
      case ButtonType.VALIDAR_VISITA:
        dispatch(
          setDialogConfig({
            shown: true,
            type: DialogType.VALIDAR_DOC,
            props: {
              title: "Estas seguro de validar la visita de:",
            },
            onAcceptClick: () => {
              dispatch(setButtonClicked({ btn: ButtonType.VALIDAR_VISITA, clicked: true }));
            },
          }),
        );
        break;
      case ButtonType.VALIDAR_DICTAMEN:
        dispatch(
          setDialogConfig({
            shown: true,
            type: DialogType.VALIDAR_DIC,
            props: {
              title: "Estas seguro de completar y validar el dictamen de:",
            },
            onAcceptClick: () => {
              dispatch(setButtonClicked({ btn: ButtonType.VALIDAR_DICTAMEN, clicked: true }));
            },
          }),
        );
        break;
      case ButtonType.LIBERAR_DISPERSION:
        dispatch(
          setDialogConfig({
            shown: true,
            type: DialogType.VALIDAR_DOC,
            props: {
              title: "Estas seguro de liberar dispersión para:",
            },
            onAcceptClick: () => {
              dispatch(
                setButtonClicked({
                  btn: ButtonType.LIBERAR_DISPERSION,
                  clicked: true,
                }),
              );
            },
          }),
        );
        break;
      case ButtonType.CONVERTIR_CLIENTE:
        dispatch(
          setDialogConfig({
            shown: true,
            type: DialogType.VALIDAR_DOC,
            props: { title: "Estas seguro de convertir a cliente:" },
            onAcceptClick: () => {
              dispatch(
                setButtonClicked({
                  btn: ButtonType.CONVERTIR_CLIENTE,
                  clicked: true,
                }),
              );
            },
          }),
        );
        break;
    }
  };

  const buttons = new Map<ButtonType, ButtonConfig>([
    [
      ButtonType.VALIDAR_AUTORIZACION,
      {
        text: "Validar autorización",
        color: "#01A388",
        fontColor: "#FFFFFF",
        disabledColor: "#7ac9b8",
      },
    ],
    [
      ButtonType.RECHAZAR,
      {
        text: "Rechazar",
        color: "#E35050",
        fontColor: "#FFFFFF",
        disabledColor: "#e79799",
      },
    ],
    [
      ButtonType.RECHAZAR_BLOQUEAR,
      {
        text: "Rechazar y Bloquear",
        color: "#BB3545",
        fontColor: "#FFFFFF",
        disabledColor: "#e59da7",
      },
    ],
    [
      ButtonType.VALIDAR_DICTAMEN,
      {
        text: "Validar Dictamen",
        color: "#00a388",
        fontColor: "#FFFFFF",
        disabledColor: "#7ac9b8",
      },
    ],
    [
      ButtonType.DOCS_FALTANTES,
      {
        text: "Documentos faltantes",
        color: "#F2704F",
        fontColor: "#FFFFFF",
        disabledColor: "#f6aa96",
      },
    ],
    [
      ButtonType.VALIDAR_EXPEDIENTE,
      {
        text: "Validar Expediente",
        color: "#528CD6",
        fontColor: "#FFFFFF",
        disabledColor: "#9ecbf3",
      },
    ],
    [
      ButtonType.VALIDAR_VISITA,
      {
        text: "Validar Visita",
        color: "#894DD8",
        fontColor: "#FFFFFF",
        disabledColor: "#bb9ae8",
      },
    ],
    [
      ButtonType.DESCARGAR_EXPEDIENTE,
      {
        text: "Descargar Expediente",
        color: "#528CD6",
        fontColor: "#FFFFFF",
        disabledColor: "#bb9ae8",
      },
    ],
    [
      ButtonType.VOLVER_VISITAR,
      {
        text: "Volver a Visitar",
        color: "#f37e61",
        fontColor: "#FFFFFF",
        disabledColor: "",
      },
    ],
    [
      ButtonType.LIBERAR_DISPERSION,
      {
        text: "Liberar dispersión",
        color: "#603797",
        fontColor: "#FFFFFF",
        disabledColor: "#b29dce",
      },
    ],
    [
      ButtonType.CONVERTIR_CLIENTE,
      {
        text: "Convertir Cliente",
        color: "#8092a8",
        fontColor: "#FFFFFF",
        disabledColor: "#b3c0cf",
      },
    ],
  ]);

  useEffect(() => {
    dispatch(setButtonClicked({ btn: undefined, clicked: false }));
  }, []);

  return (
    <Grid container spacing={1}>
      {buttonsVisibles.map((btn, idx) => {
        return (
          <Button
            key={idx}
            sx={{
              fontSize: "14px",
              backgroundColor: btn.disabled ? buttons.get(btn.btn)?.disabledColor : buttons.get(btn.btn)?.color,
              color: buttons.get(btn.btn)?.fontColor,
              width: "130px",
              lineHeight: "18px",
            }}
            onClick={handleOnButtonClicked}
            data-type={btn.btn}
            disabled={btn.disabled}
          >
            {buttons.get(btn.btn)?.text}
          </Button>
        );
      })}
    </Grid>
  );
};
