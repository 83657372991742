import { useEffect, useState } from "react";
import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import { DialogType } from "@interfaces/slices";
import { TablaBalanceGeneral } from "@components/TablaBalanceGeneral";
import { TablaEstadosResultados } from "@components/TablaEstadoResultados";
import { usePostBalanceSheetMutation, usePostIncomeStatementMutation } from "@services/api/sat_core";
import { setDialogConfig, setPrintingFinancialReady, setPrintingMode } from "@store/slices/componentsSlice";

import DownloadIcon from "@assets/download_icon.svg";

export const Financieros = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const printingMode = useSelector((state: RootState) => state.components.printingProfileReport.printingMode);

  const [triggerPostBalanceSheet, balanceSheet] = usePostBalanceSheetMutation();
  const [triggerPostIncomeStatement, incomeStatement] = usePostIncomeStatementMutation();

  const handleOnDownloadReportClicked = () => {
    dispatch(setPrintingMode(true));
    dispatch(
      setDialogConfig({
        shown: true,
        fullscreen: true,
        type: DialogType.DESCARGAR_REPORTE,
        props: undefined,
      }),
    );
  };

  const requestData = async (rfc: string, startDate: string, endDate: string) => {
    try {
      setIsLoading(true);
      Promise.all([
        triggerPostBalanceSheet({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
        triggerPostIncomeStatement({
          rfc: rfc,
          startDate: startDate,
          endDate: endDate,
        }),
      ]).finally(() => {
        setIsLoading(false);
      });
    } catch (error) {
      console.log("Error al consultar datos mensuales, %s", error);
    } finally {
      if (printingMode) {
        dispatch(setPrintingFinancialReady(true));
      }
    }
  };

  useEffect(() => {
    const startDate = `${new Date().getFullYear() - 2}-01-01`;
    const endDate = `${new Date().getFullYear()}-12-31`;
    requestData(applicationSelected.rfc, startDate, endDate);
  }, []);

  return (
    <Grid container pt={1}>
      <Grid size={12} textAlign={"end"} mb={2} display={printingMode ? "none" : "flex-end"}>
        <Button
          endIcon={<img src={DownloadIcon} />}
          variant="text"
          sx={{ color: "#528CD6" }}
          onClick={handleOnDownloadReportClicked}
        >
          Descargar Reporte
        </Button>
      </Grid>
      <Grid size={12}>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={0} mb={1}>
          Balance General
        </Typography>
        <Box minWidth={"600px"} mx={2}>
          <TablaBalanceGeneral data={balanceSheet.data} isLoading={isLoading} />
        </Box>
      </Grid>
      <Grid size={12}>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={6} mb={1}>
          Estado de Resultados
        </Typography>
        <Box minWidth={"600px"} mx={2}>
          <TablaEstadosResultados data={incomeStatement.data} isLoading={isLoading} />
        </Box>
      </Grid>
    </Grid>
  );
};
