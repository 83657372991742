import { Grid2 as Grid, Typography } from "@mui/material";
import React from "react";

export const createRowTable = (
  t1: string,
  v1: string,
  t2: string,
  v2: string,
  colorV1: string = "#002652",
  colorV2: string = "#002652",
) => {
  return (
    <>
      <Grid size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
        <div>
          <Grid container>
            <Grid size={{ xs: 6 }}>
              <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                {t1}
              </Typography>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <Typography color={colorV1}>{v1}</Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid
        size={{ xs: 6 }}
        p={1}
        borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
        borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
      >
        <div>
          <Grid container ml={4}>
            <Grid size={{ xs: 6 }}>
              <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                {t2}
              </Typography>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <Typography color={colorV2}>{v2}</Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export const createSimpleRow = (
  t1: string,
  v1: string,
  cols: Array<number> = [3, 9],
  extraItem: React.ReactNode = undefined,
) => {
  return (
    <>
      <Grid size={{ xs: cols[0] }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
        <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
          {t1}
        </Typography>
      </Grid>
      <Grid size={{ xs: cols[1] }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
        <Typography color={"#002652"}>{v1}</Typography>
      </Grid>
      {extraItem && (
        <Grid size={{ xs: cols[2] }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
          {extraItem}
        </Grid>
      )}
    </>
  );
};
