import { useState } from "react";
import { Box, Button, Grid2 as Grid, Stack, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import { DialogType } from "@interfaces/slices";
import { setDialogConfig } from "@store/slices/componentsSlice";
// import { setApplicationStateCode } from "@store/slices/appSlice";
// import { usePostStatusApplicationMutation } from "@services/api/applications";
// import { useLazyGetDocumentsQuery, usePatchDocumentMutation } from "@services/api/documents";

import OkCheck from "@assets/OkCheck.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export interface ValidarProps {
  type: "VISIT" | "COMPLETE" | "VALIDATE_DOC" | "VALIDATE_EXP" | "DELETE_DOC";
  date?: string;
  time?: string;
  title: string;
  subtitle?: string;
  isProcessing?: boolean;
  onBackClick: () => void;
  onContinueClick: () => void;
}

export const Validar = (props: ValidarProps) => {
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  // const [triggerGetDocuments] = useLazyGetDocumentsQuery();
  // const [triggerPatchDocument] = usePatchDocumentMutation();
  // const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  // const userSession = useSelector((state: RootState) => state.app.session.user);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const handleOnBackClicked = () => {
    dispatch(
      setDialogConfig({
        shown: false,
        type: DialogType.NONE,
        props: undefined,
      }),
    );
    props.onBackClick();
  };

  const handleOnContinueClicked = () => {
    switch (props.type) {
      case "VISIT":
        setIsProcessing(true);
        props.onContinueClick();
        break;
      case "VALIDATE_DOC":
        setIsProcessing(true);
        props.onContinueClick();
        break;
      case "VALIDATE_EXP":
        setIsProcessing(true);
        props.onContinueClick();
        break;
      case "COMPLETE":
        setIsProcessing(true);
        props.onContinueClick();
        break;
      case "DELETE_DOC":
        setIsProcessing(true);
        props.onContinueClick();
        break;
    }
  };

  return (
    <Box margin={4}>
      <Stack textAlign={"center"} spacing={2}>
        {props.type === "DELETE_DOC" ? (
          <HighlightOffIcon sx={{ alignSelf: "center", fontSize: 65, color: "#E35050" }} />
        ) : (
          <img src={OkCheck} height={"65px"} />
        )}

        <Typography variant="body1" fontWeight={600}>
          {props.title}
        </Typography>
        <Typography variant="body1" fontWeight={600} color="#528CD6">
          {applicationSelected.razonSocial}
        </Typography>
        <Typography variant="h4">
          ID SOLICITUD:{" "}
          <Typography component={"span"} variant="h4" fontWeight={600}>
            {applicationSelected.solicitudId}
          </Typography>
        </Typography>
        {props.type === "VISIT" && (
          <>
            <Typography variant="body1" fontWeight={400}>
              El día y la hora:
            </Typography>
            <Typography variant="body1" fontWeight={600} color="#528CD6">
              {props.date} a las {props.time}
            </Typography>
          </>
        )}

        {props.type === "COMPLETE" && <></>}

        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-around"}
          alignItems={"center"}
          marginTop={10}
          columnSpacing={1}
        >
          <Grid>
            <Button variant={"blue_outlined"} onClick={handleOnBackClicked} disabled={isProcessing}>
              Regresar
            </Button>
          </Grid>
          <Grid>
            <Button
              variant={"contained"}
              type="submit"
              loading={isProcessing}
              disabled={isProcessing}
              onClick={handleOnContinueClicked}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};
