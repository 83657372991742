import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Shareholder } from "@interfaces/slices";
import { DocumentInfo } from "@interfaces/documents";
import { DialogConfig, ButtonType, OptionApplicationMenu, DocumentAction, DialogType } from "@interfaces/slices";

export interface Components {
  dialog: DialogConfig;
  buttonPanel: {
    buttons: Array<{ btn: ButtonType; disabled: boolean }>;
    buttonClicked: {
      btn: ButtonType | undefined;
      clicked: boolean;
    };
  };
  menuApplication: {
    optionSelected: OptionApplicationMenu;
  };
  documentAction: {
    action: DocumentAction | undefined;
    doc: DocumentInfo | undefined;
  };
  printingProfileReport: {
    printingMode: boolean;
    module: {
      generalReady: boolean;
      downloadSatReady: boolean;
      profileReady: boolean;
      dashboardReady: boolean;
      financialReady: boolean;
    };
  };
  shareholdersStructure: {
    shareholders: Array<Shareholder>;
    editingMode: boolean;
  };
}

const initialState: Components = {
  dialog: {
    shown: false,
    type: DialogType.NONE,
    props: undefined,
    onRejectClick: () => {},
    onAcceptClick: () => {},
    onCloseClick: () => {},
  },
  buttonPanel: {
    buttons: [],
    buttonClicked: {
      clicked: false,
      btn: undefined,
    },
  },
  menuApplication: {
    optionSelected: OptionApplicationMenu.PERFIL,
  },
  documentAction: {
    action: DocumentAction.SEE,
    doc: undefined,
  },
  printingProfileReport: {
    printingMode: false,
    module: {
      generalReady: false,
      downloadSatReady: false,
      profileReady: false,
      dashboardReady: false,
      financialReady: false,
    },
  },
  shareholdersStructure: {
    shareholders: [],
    editingMode: false,
  },
};

export const componentsSlice = createSlice({
  name: "components",
  initialState,
  reducers: {
    setDialogConfig: (state, action: PayloadAction<DialogConfig>) => {
      state.dialog = action.payload;
    },
    setDialogConfigProcessing: (state, action: PayloadAction<boolean>) => {
      state.dialog.isProcessing = action.payload;
    },
    setVisibleButtons: (state, action: PayloadAction<Array<{ btn: ButtonType; disabled: boolean }>>) => {
      state.buttonPanel.buttons = action.payload;
    },
    setOptionAppSelected: (state, action: PayloadAction<OptionApplicationMenu>) => {
      state.menuApplication.optionSelected = action.payload;
    },
    setDocumentAction: (state, action: PayloadAction<{ action: DocumentAction; doc: DocumentInfo }>) => {
      state.documentAction = action.payload;
    },
    setButtonClicked: (state, action: PayloadAction<{ btn: ButtonType | undefined; clicked: boolean }>) => {
      state.buttonPanel.buttonClicked = action.payload;
    },
    setPrintingMode: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.printingMode = action.payload;
    },
    setPrintingGeneralReady: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.module.generalReady = action.payload;
    },
    setPrintingDownloadSatReady: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.module.downloadSatReady = action.payload;
    },
    setPrintingProfileReady: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.module.profileReady = action.payload;
    },
    setPrintingDashboardReady: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.module.dashboardReady = action.payload;
    },
    setPrintingFinancialReady: (state, action: PayloadAction<boolean>) => {
      state.printingProfileReport.module.financialReady = action.payload;
    },
    setPrintingReset: (state) => {
      state.printingProfileReport = {
        printingMode: false,
        module: {
          generalReady: false,
          downloadSatReady: false,
          profileReady: false,
          dashboardReady: false,
          financialReady: false,
        },
      };
    },
    setShareholders: (state, action: PayloadAction<Array<Shareholder>>) => {
      state.shareholdersStructure.shareholders = action.payload;
    },
    setShareholderEditingMode: (state, action: PayloadAction<boolean>) => {
      state.shareholdersStructure.editingMode = action.payload;
    },
  },
});

export const {
  setDialogConfig,
  setDialogConfigProcessing,
  setVisibleButtons,
  setOptionAppSelected,
  setDocumentAction,
  setButtonClicked,
  setPrintingMode,
  setPrintingGeneralReady,
  setPrintingDownloadSatReady,
  setPrintingProfileReady,
  setPrintingDashboardReady,
  setPrintingFinancialReady,
  setPrintingReset,
  setShareholders,
  setShareholderEditingMode,
} = componentsSlice.actions;
export default componentsSlice.reducer;
