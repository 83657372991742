import { UUID } from "crypto";

import { ApplicationSelected } from "@interfaces/slices";
import { BpmUser, Permission } from "@interfaces/users";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppSlice {
  applicationSelected: ApplicationSelected;
  session: {
    user: BpmUser;
    permissions: Array<Permission>;
    recovery: {
      username: string;
    };
  };
  validateFileModal: {
    show: boolean;
  };
}

const initialState: AppSlice = {
  applicationSelected: {
    id: undefined,
    solicitudId: 0,
    razonSocial: "",
    rfc: "",
    nombre: "",
    montoSolicitado: "",
    plazoSolicitado: 0,
    tiempoPantalla: 0,
    tiempoTotal: 0,
    etapa: "",
    subEtapa: "",
    estatus: "",
    pantalla: "",
    macroCanal: "",
    canal: "",
    asignacion: "",
    perdioInteres: 0,
    montoContratado: "",
    plazoContratado: 0,
    tiempoTotalProceso: "",
    dictamenCompletado: false,
    fullData: "",
    company_docs_validated: false,
    shareholder_docs_validated: false,
    owners_docs_validated: false,
    canalId: "",
    correoEjecutivo: "",
    tax_systems: {
      id: undefined,
      code: "",
      name: "",
    },
    status_code: "",
    existsSDC: false,
    montoSolicitadoOriginal: 0,
    plazoSolicitadoOriginal: 0,
    usuario: {
      nombre: "",
      correo: "",
    },
    accionistaMayoritario: {
      nombre: "",
      rfc: "",
    },
    clientDetailId: undefined,
    productId: undefined,
    statusId: undefined,
    fechaModelo: "",
    montoContratadoOriginal: 0,
    plazoContratadoOriginal: 0,
    loanApplicationConditionActive: {
      id: undefined,
    },
  },
  session: {
    user: {
      id: "0-0-0-0-0",
      name: "",
      last_name: "",
      last_name_2: "",
      rol_id: "0-0-0-0-0",
      created_at: "",
      updated_at: "",
    },
    permissions: [],
    recovery: {
      username: "",
    },
  },
  validateFileModal: {
    show: false,
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setApplicationSelected: (state, action: PayloadAction<ApplicationSelected>) => {
      state.applicationSelected = action.payload;
    },
    setApplicationCompleted: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.dictamenCompletado = action.payload;
    },
    setApplicationState: (state, action: PayloadAction<{ code: string; estatus: string; statusId: UUID }>) => {
      state.applicationSelected.statusId = action.payload.statusId;
      state.applicationSelected.status_code = action.payload.code;
      state.applicationSelected.estatus = action.payload.estatus;
    },
    setApplicationStateCode: (state, action: PayloadAction<string>) => {
      state.applicationSelected.status_code = action.payload;
    },
    setBpmUserLogged: (state, action: PayloadAction<BpmUser>) => {
      state.session.user = action.payload;
    },
    setUserPermissions: (state, action: PayloadAction<Array<Permission>>) => {
      state.session.permissions = action.payload;
    },
    setCompanyDocsValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.company_docs_validated = action.payload;
    },
    setShareHolderDocsValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.shareholder_docs_validated = action.payload;
    },
    setOwnersDocsValidated: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.owners_docs_validated = action.payload;
    },
    setShowValidateFileModal: (state, action: PayloadAction<boolean>) => {
      state.validateFileModal.show = action.payload;
    },
    setRecoveryUsername: (state, action: PayloadAction<string>) => {
      state.session.recovery.username = action.payload;
    },
    setExistSDC: (state, action: PayloadAction<boolean>) => {
      state.applicationSelected.existsSDC = action.payload;
    },
  },
});

export const {
  setApplicationSelected,
  setApplicationCompleted,
  setApplicationState,
  setApplicationStateCode,
  setBpmUserLogged,
  setUserPermissions,
  setCompanyDocsValidated,
  setShareHolderDocsValidated,
  setOwnersDocsValidated,
  setShowValidateFileModal,
  setRecoveryUsername,
  setExistSDC,
} = appSlice.actions;
export default appSlice.reducer;
