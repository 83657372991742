import { conversionToTreeViewItem } from "@helpers/generals";
import { baseApi } from "./baseApi";
import {
  BalanceSheetResponse,
  CashFlow,
  CashFlowResponse,
  CustomerSupplier,
  CustomerSupplierInvoiceResponse,
  Expenditure,
  IncomeStatementResponse,
  MonthlyExpendituresResponse,
  MonthlySalesResponse,
  ProductsAndServicesResponse,
  ProductService,
  Sale,
} from "@interfaces/syntage";
import { ModelDownloadStatus, ModelDownloadStatusResponse } from "@interfaces/model";
import { UUID } from "crypto";

const satCore = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postMonthlySales: build.mutation<Array<Sale>, { rfc: string; startDate: string; endDate: string }>({
      query: ({ rfc, startDate, endDate }) => ({
        url: "/sat_core/dashboards/monthly-total-sales-revenue",
        method: "POST",
        body: {
          rfc,
          date_from: startDate,
          date_to: endDate,
        },
      }),
      transformResponse: (response: MonthlySalesResponse) => {
        return response.data;
      },
    }),
    postMonthlyExpenditures: build.mutation<Array<Expenditure>, { rfc: string; startDate: string; endDate: string }>({
      query: ({ rfc, startDate, endDate }) => ({
        url: "/sat_core/dashboards/monthly-total-expenditures",
        method: "POST",
        body: {
          rfc,
          date_from: startDate,
          date_to: endDate,
        },
      }),
      transformResponse: (response: MonthlyExpendituresResponse) => {
        return response.data;
      },
    }),
    postMonthlyCashFlow: build.mutation<Array<CashFlow>, { rfc: string; startDate: string; endDate: string }>({
      query: ({ rfc, startDate, endDate }) => ({
        url: "/sat_core/dashboards/monthly-total-cash-flow",
        method: "POST",
        body: {
          rfc,
          date_from: startDate,
          date_to: endDate,
        },
      }),
      transformResponse: (response: CashFlowResponse) => {
        return response.data;
      },
    }),
    postMonthlyCustomerSupplierInvoice: build.mutation<
      { clients: Array<CustomerSupplier>; suppliers: Array<CustomerSupplier> },
      { rfc: string; startDate: string; endDate: string }
    >({
      query: ({ rfc, startDate, endDate }) => ({
        url: "/sat_core/dashboards/customer-supplier-invoice-concentration",
        method: "POST",
        body: {
          rfc,
          date_from: startDate,
          date_to: endDate,
        },
      }),
      transformResponse: (response: CustomerSupplierInvoiceResponse) => {
        return {
          clients: response.data.clients ?? [],
          suppliers: response.data.suppliers ?? [],
        };
      },
    }),
    postMonthlyProductsAnsServices: build.mutation<
      {
        bought: Array<ProductService>;
        sold: Array<ProductService>;
      },
      { rfc: string; startDate: string; endDate: string }
    >({
      query: ({ rfc, startDate, endDate }) => ({
        url: "/sat_core/dashboards/products-and-services",
        method: "POST",
        body: {
          rfc,
          date_from: startDate,
          date_to: endDate,
        },
      }),
      transformResponse: (response: ProductsAndServicesResponse) => {
        return {
          bought: response.data.bought ?? [],
          sold: response.data.sold ?? [],
        };
        // return response.data;
      },
    }),
    postBalanceSheet: build.mutation<Array<any>, { rfc: string; startDate: string; endDate: string }>({
      query: ({ rfc, startDate, endDate }) => ({
        url: "/sat_core/dashboards/balance-sheet",
        method: "POST",
        body: {
          rfc,
          date_from: startDate,
          date_to: endDate,
        },
      }),
      transformResponse: (response: BalanceSheetResponse) => {
        if (Array.isArray(response.data) || response.data.data?.length === 0) {
          return [];
        }
        return response.data.data.map((item) => conversionToTreeViewItem(item, 0));
      },
    }),
    postIncomeStatement: build.mutation<Array<any>, { rfc: string; startDate: string; endDate: string }>({
      query: ({ rfc, startDate, endDate }) => ({
        url: "/sat_core/dashboards/income-statement",
        method: "POST",
        body: {
          rfc,
          date_from: startDate,
          date_to: endDate,
        },
      }),
      transformResponse: (response: IncomeStatementResponse) => {
        return response.data.map((item) => conversionToTreeViewItem(item, 0));
      },
    }),
    postModelDownloadStatus: build.mutation<ModelDownloadStatus, { applicationId: UUID; rfc: string }>({
      query: ({ applicationId, rfc }) => ({
        url: "/sat_core/dashboards/model-status",
        method: "POST",
        body: {
          rfc: rfc,
          loan_application_id: applicationId,
        },
      }),
      transformResponse: (response: ModelDownloadStatusResponse) => {
        return response.data;
      },
    }),
  }),
});

export const {
  usePostMonthlySalesMutation,
  usePostMonthlyExpendituresMutation,
  usePostMonthlyCashFlowMutation,
  usePostMonthlyCustomerSupplierInvoiceMutation,
  usePostMonthlyProductsAnsServicesMutation,
  usePostBalanceSheetMutation,
  usePostIncomeStatementMutation,
  usePostModelDownloadStatusMutation,
} = satCore;
