import { formatCurrency } from "@helpers/formats";
import { Grid2 as Grid, IconButton, Typography } from "@mui/material";
import {
  TreeItem2Props,
  useTreeItem2,
  TreeItem2Provider,
  TreeItem2Root,
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2GroupTransition,
  RichTreeView,
  TreeViewBaseItem,
} from "@mui/x-tree-view";
import { forwardRef, useState, useEffect } from "react";

interface TreeViewItemCustom extends TreeViewBaseItem {}

interface TablaBalanceGeneralProps {
  data: any | undefined;
  isLoading?: boolean;
}

export const TablaBalanceGeneral = (props: TablaBalanceGeneralProps) => {
  const [years, setYears] = useState<Array<string>>([]);

  const CustomTreeItem = forwardRef(function CustomTreeItem(
    { id, itemId, label, disabled, children }: TreeItem2Props,
    ref: React.Ref<HTMLLIElement>,
  ) {
    const [item, setItem] = useState<any>({});
    const [years, setYears] = useState<Array<string>>([]);
    const { getRootProps, getContentProps, getLabelProps, getGroupTransitionProps, getIconContainerProps, status } =
      useTreeItem2({ id, itemId, label, disabled, children, rootRef: ref });

    const findItem = (items: Array<TreeViewItemCustom>, label: string): any => {
      const find = items?.find((item) => item.label === label);

      if (find) {
        return find;
      }

      const result = items
        .map<number | undefined>((item) => {
          if (item.children) {
            return findItem(item.children, label);
          }
        })
        .find((value) => value != undefined);

      return result;
    };

    useEffect(() => {
      const item = findItem(props.data ?? [], label as string);

      if (item) {
        const years = Object.keys(item).filter((key) => isNaN(parseInt(key)) == false);
        setYears(years.map((year) => item[year].Total));
        setItem(item);
      }
    }, [label]);

    return (
      <TreeItem2Provider itemId={itemId}>
        <TreeItem2Root {...getRootProps()} sx={{ "--TreeView-itemChildrenIndentation": 0 }}>
          <TreeItem2Content
            {...getContentProps()}
            sx={{
              background: item?.lvl === 0 ? "#528cd6" : "#e3f2f8",
              borderRadius: 0,
              "&:hover": { backgroundColor: item?.lvl === 0 ? "#528cd6" : "#e3f2f8" },
            }}
          >
            <Grid container width={"100%"}>
              <Grid pl={2 * (item.lvl ?? 0)}>
                <TreeItem2IconContainer
                  {...getIconContainerProps()}
                  sx={{
                    pt: 0.5,
                    color: item?.lvl === 0 ? "#FFF" : "#000",
                    fontWeight: 500,
                  }}
                >
                  {status.expandable && (
                    <>
                      {status.expanded ? (
                        <IconButton
                          sx={{
                            width: "10px",
                            height: "10px",
                            fontSize: "16px",
                            fontWeight: 600,
                            pt: 1.2,
                            color: item.lvl ? "#000" : "#FFF",
                          }}
                        >
                          -
                        </IconButton>
                      ) : (
                        <IconButton
                          sx={{
                            width: "10px",
                            height: "10px",
                            fontSize: "16px",
                            fontWeight: 600,
                            color: item.lvl ? "#000" : "#FFF",
                            pt: 1.2,
                          }}
                        >
                          +
                        </IconButton>
                      )}
                    </>
                  )}
                </TreeItem2IconContainer>
              </Grid>
              <Grid>
                <TreeItem2Label
                  {...getLabelProps()}
                  sx={{ color: item?.lvl === 0 ? "#FFF" : "#000", fontWeight: 500 }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              width={"100%"}
              ml={1}
              justifyContent={"space-between"}
              textAlign={"center"}
              sx={{ color: item?.lvl === 0 ? "#FFF" : "#000" }}
            >
              {years.map((year, idx) => {
                return (
                  <Grid key={idx} size={"grow"} mx={2} container justifyContent={"space-between"}>
                    <Typography>$</Typography>
                    <Typography>{year ? formatCurrency(parseFloat(year), "", 0) : "-"}</Typography>
                  </Grid>
                );
              })}
            </Grid>
          </TreeItem2Content>

          {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
        </TreeItem2Root>
      </TreeItem2Provider>
    );
  });

  useEffect(() => {
    if (props.data !== undefined) {
      if (props.data.length === 0) return;

      const years = Object.keys(props.data[0]).filter((key) => isNaN(parseInt(key)) == false);
      setYears(years);
      // setItem(item);
    }
  }, [props.data]);

  return (
    <>
      <Grid container sx={{ background: "#002652", color: "#FFF" }} textAlign={"center"} py={1}>
        <Grid size={6}>
          <Typography variant="h4">Categoría / Periodo</Typography>
        </Grid>
        {years.map((year, idx) => {
          return (
            <Grid key={idx} size={"grow"}>
              <Typography variant="h4">{year}</Typography>
            </Grid>
          );
        })}
      </Grid>
      {props.isLoading ? (
        <Grid container sx={{ background: "#e3f2f8", padding: 1 }}>
          <Grid>
            <Typography variant="h4">Cargando información...</Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          {(props.data ?? []).length === 0 ? (
            <Grid container sx={{ background: "#e3f2f8", padding: 1 }}>
              <Grid>
                <Typography variant="h4">No hay información para mostrar</Typography>
              </Grid>
            </Grid>
          ) : (
            <RichTreeView items={props.data ?? []} slots={{ item: CustomTreeItem }} sx={{ background: "#e3f2f8" }} />
          )}
        </>
      )}
    </>
  );
};
